import './Loading.css'

function Loading(props) {


  const matchesS = props.matchesS

  return (
    <div>



    


  </div>


  );
}

export default Loading;