import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Wallet from './Wallet';

function MyRoutes() {
        return(
        <div>
        <Router>
          <Routes>
            <Route exact path="/" element={<Wallet />} />
          </Routes>
        </Router>
      </div>
  )
}

export default MyRoutes;