import Button from '@mui/material/Button';
import { useState, useEffect, useContext} from 'react'
import styled from 'styled-components';
import * as serLib from '@emurgo/cardano-serialization-lib-asmjs/cardano_serialization_lib';
import {Buffer} from 'buffer'
import CircularProgress from '@mui/material/CircularProgress';

import Grid from '@mui/material/Grid'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './ClaimClay.css'

import {MainClaimContext} from './WalletContext'

import CustomCircularProgress from './CustomCircularProgress';

  const ConnectButton = styled.button`
    position: relative;
    width: ${props => props.matchesS ? '220px' : '150px'};
    height: ${props => props.matchesS ? '70px' : '50px' };
    display: flex;
    justify-content: center;
    align-items: center;
    background: #2d4359;
    overflow: hidden;
    border-radius: 18px;
    opacity: ${props => props.theme.op}; 
    border:0px;

    font-family: shortstack;

    cursor: pointer;

    margin-bottom: 5px;
    
  :before
  {
    content: '';
    position: absolute;
    width: 250px;
    height: 140%;
    background: linear-gradient(-180deg,#f98763,#53C9EE);
    opacity: ${props => props.theme.op}; 
    animation: ${props => props.theme.anim}; 
  }

  @keyframes animate 
  {
    0%
    {
      transform: rotate(0deg);
    }
    100%
    {
      transform: rotate(360deg);
    }
  }
  :after
  {
    content: '';
    position: absolute;
    inset : 2px;
    background: linear-gradient(30deg, rgba(47,53,85,1) 0%, rgba(29,58,61,1) 100%);
    box-shadow: inset -7px -7px 38px rgba(0, 0, 0), inset 3px 1px 10px rgba(255, 255, 255, 0.4);
    border-radius: 18px;
    z-index: 1;
    border:0px;
  }

  :hover
  {
    position: relative;
    width: ${props => props.matchesS ? '220px' : '150px'};
    height: ${props => props.matchesS ? '70px' : '50px' };
    display: flex;
    justify-content: center;
    align-items: center;
    background: #2d4359;
    overflow: hidden;
    border-radius: 18px;
    opacity: ${props => props.theme.op}; 
  }


  :hover:before
  {
    content: '';
    position: absolute;
    width: ${props => props.matchesS ? '250px' : '180px'};
    height: 250%;
    background: linear-gradient(120deg,#f98763,#53C9EE);
    opacity: ${props => props.theme.op}; 
    animation: ${props => props.theme.anim}; 
  }
  `
const theme = {
  anim: 'animate 7s linear infinite',
  op: 0.9
}

const DropDown = styled.div`
  

` 

const WalletConnected = styled.button`

 position: relative;
    width: 220px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url('wallet_connected.svg');
    overflow: hidden;
    border-radius: 18px;
    opacity: ${props => props.theme.op}; 
    border: 0px;
    cursor: pointer;

    background-repeat: no-repeat;
    background-size: cover;

    margin-bottom: 5px;

`

const ConnectedAcoount = styled.button`
    position: relative;
    width: 200px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #2d4359;
    overflow: hidden;
    border-radius: 18px;
    opacity: ${props => props.theme.op}; 
    border:0px;

    cursor: pointer;
    
  :before
  {
    content: '';
    position: absolute;
    width: 250px;
    height: 140%;
    background: linear-gradient(-180deg,#f98763,#53C9EE);
    opacity: ${props => props.theme.op}; 
    animation: ${props => props.theme.anim}; 
  }

  @keyframes animate 
  {
    0%
    {
      transform: rotate(0deg);
    }
    100%
    {
      transform: rotate(360deg);
    }
  }
  :after
  {
    content: '';
    position: absolute;
    inset : 2px;
    background: linear-gradient(30deg, rgba(47,53,85,1) 0%, rgba(29,58,61,1) 100%);
    box-shadow: inset -7px -7px 38px rgba(0, 0, 0), inset 3px 1px 10px rgba(255, 255, 255, 0.4);
    border-radius: 18px;
    z-index: 1;
    border:0px;
  }

  :hover
  {
    position: relative;
    width: 200px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #2d4359;
    overflow: hidden;
    border-radius: 18px;
    opacity: ${props => props.theme.op}; 
  }


  :hover:before
  {
    content: '';
    position: absolute;
    width: 250px;
    height: 250%;
    background: linear-gradient(120deg,#f98763,#53C9EE);
    opacity: ${props => props.theme.op}; 
    animation: ${props => props.theme.anim}; 
  }
` 

const DropDownItem = styled.div`
  position: relative;
  width: 220px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0,0,0,0.25);
  overflow: hidden;
  border-radius: 0px;
  opacity: 0.92;
  cursor: pointer;

  z-index: 5;

  backdrop-filter: 'blur(80px)';



:hover
{
  content: '';
  position: relative;
  width: 220px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(90deg, rgba(132,211,255,1) 0%, rgba(148,130,244,1) 49%, rgba(246,141,141,1) 100%);
  opacity: 0.92;

}


:after
{
  content: '';
  position: absolute;
  inset : 2px;
  background: linear-gradient(138deg, rgba(53,61,104,1) 0%, rgba(51,57,77,1) 44%, rgba(29,58,61,1) 100%);
  border-radius: 0px;
  z-index: 1;
  backdrop-filter: 'blur(80px)';

}
`


const ButtonText = styled.h1`
  position: relative;
  font-size: 1.8em;
  font-family: 'shortstack';
  letter-spacing: 0.1em;
  text-shadow: 2px 2px 2px rgba(0,0,0,0.5);
  color: #fff;
  z-index: 4;
`



export function DisplayAccount(props){

  const matchesS = props.matchesS

  const isBusy = props.isBusy

  const standardStyle = {
    width:'40px',
    height:'40px', 
    marginTop: '10px',
    zIndex:'4', 
    marginLeft:'0px',
    marginRight:'0px'
  }

  const walletLogoOffsets = {

    'test':{
    'nami':{
      marginLeft: '-35px',
      marginRight:'30px'
    },
    'eternl':{
      marginLeft: '-45px',
      marginRight:'35px'
    },
    'yoroi':{
      marginLeft: '-45px',
      marginRight:'35px'
    },
    'flint':{
      marginLeft: '-55px',
      marginRight:'35px'
    }
  }
  }

  let cur_lovelace = props.lovelace
  let activeWallet = props.activeWallet
  let walletLogos = props.walletLogos



  if (activeWallet in walletLogoOffsets){
    standardStyle.marginLeft = walletLogoOffsets[activeWallet].marginLeft
    standardStyle.marginRight = walletLogoOffsets[activeWallet].marginRight
  }

  let ada_balance = cur_lovelace / 1000000
  
  if (ada_balance < 1000){
    ada_balance = (parseInt(cur_lovelace) / 1000000).toFixed(2);
  }else if(ada_balance < 1000000){
    ada_balance = (parseInt(cur_lovelace) / 1000000000).toFixed(2) + 'K';
  }else{
    ada_balance = (parseInt(cur_lovelace) / 1000000000000).toFixed(2) + 'M';
  }

  let walletLogo = '/' + walletLogos[activeWallet]

  return(
    <WalletConnected matchesS={matchesS} >
      <Grid container justifyContent = 'center' alignContent = 'center' spacing={0}>
          <Grid item xs={3}>
              <img src={walletLogo} style={standardStyle} /> 
          </Grid>
          <Grid item xs={8}>
            <ButtonText>{ada_balance}</ButtonText>
          </Grid>
      </Grid>
    </WalletConnected>
  )
}


function Connect(props) {

  const setIsWalletLoading = props.setIsWalletLoading

  const pushDown = props.pushDown

  const matchesS = true

    const notify = (message) => toast(message);
    const notifySuccess = (message) => toast.success(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}><img src="/success.png" style={{position:'absolute', left:'5px',width:'40px', height:'40px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)
    const notifyWarn = (message) => toast.warn(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}><img src="/warning.png" style={{position:'absolute', left:'5px',width:'40px', height:'40px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)
    const notifyError = (message) => toast.error(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}><img src="/error.png" style={{position:'absolute', left:'3px',width:'45px', height:'45px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)
    const notifyInfo = (message) => toast.info(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}><img src="/info.png" style={{position:'absolute', left:'5px',width:'40px', height:'40px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)


  const {activeWallet, address, stake_address, lovelace, tokens, selectedTokens, setActiveWallet, setAddress, setStakeAddress, setLovelace, setTokens, setSelectedTokens, reset} = useContext(MainClaimContext)

  const eligible_policies = ['40fa2aa67258b4ce7b5782f74831d46a84c59a0ff0c28262fab21728','94da605878403d07c144fe96cd50fe20c16186dd8d171c78ed6a8768']

  const [buttonActive, setButtonActive] = useState(false)

  //const [activeWallet, setActiveWallet] = useState('')

  const [alertMessage, setAlertMessage] = useState()

  const [accountChange, setAccountChange] = useState()

  const [tempWalletInfo, setTempWalletInfo] = useState()

  const [isBusy, setIsBusy] = useState(false)




  const walletLogos = {
    nami:'nami.svg',
    eternl:'eternl.png',
    yoroi:'yoroi.svg',
    flint:'flint.svg'
  }

  const buttonStyle = {   
    position: 'absolute',
    width: '200px',
    height: '70px',
    background: 'linear-gradient(-180deg,#f98763,#53C9EE)',
    opacity: '0.9',
    borderRadius: '18px'}



async function getWalletBalance(){


  

  let cur_wallet = activeWallet

  if (cur_wallet == 'yoroi'){
    notifyInfo('This may take a while...')
  }


  setSelectedTokens([])
  let cardano;
  cardano = await window.cardano[activeWallet]
  cardano = await cardano.enable()

  let address_encoded = await cardano.getUsedAddresses()
  address_encoded = address_encoded[0]

  let address_decoded = serLib.Address.from_bytes(Buffer.from(address_encoded,"hex")).to_bech32()

  //console.log(address_decoded)

  let encoded_balance = await cardano.getBalance()
  let balance_byte_str = serLib.Value.from_bytes(Buffer.from(encoded_balance, 'hex'))
  let lovelace_balance = balance_byte_str.coin().to_str()

  let current_ada_balance = (parseInt(lovelace_balance) / 1000000).toFixed(2);

  //console.log(current_ada_balance + ' ADA')

  let multiasset_balance = balance_byte_str.multiasset()
    let assets = []
    assets.push({ unit: "lovelace", quantity: lovelace_balance})

     if (multiasset_balance) {
        const multiAssets = multiasset_balance.keys()
        for (let j = 0; j < multiasset_balance.len(); j++) {
            const policy = multiAssets.get(j);
              
            const policyAssets = multiasset_balance.get(policy);
            const assetNames = policyAssets.keys();
            for (let k = 0; k < assetNames.len(); k++) {
                const policyAsset = assetNames.get(k);
                const quantity = policyAssets.get(policyAsset).to_str();
                const asset_hash = Buffer.from(policy.to_bytes(), "hex").toString("hex") + Buffer.from(policyAsset.name(), "hex").toString("hex");
                assets.push({unit: asset_hash, quantity: quantity})
            }
          }
        }

        let tokens_list = assets.slice(1)

      /*
     let tokens_list = assets.slice(1)

     let cur_eligible_assets = []

     for (let i = 0; i < tokens_list.length; i++) {
      let temp_token = String(tokens_list[i].unit)
        
        let cur_policy = temp_token.slice(0,56)
        let cur_asset = temp_token.slice(56,)
        cur_asset = Buffer(cur_asset, 'hex').toString('utf8');

        if (eligible_policies.includes(cur_policy)){
          console.log(cur_policy)
          console.log(cur_asset)
          cur_eligible_assets.push(temp_token)
      } 
    }
    */

    let temp = serLib.Address.from_bech32(address_decoded)
    temp = serLib.BaseAddress.from_address(temp)
    let stake_cred = temp.stake_cred()
    let reward_addr_bytes = new Uint8Array(29)
    reward_addr_bytes.set([0xe1], 0)
    reward_addr_bytes.set(stake_cred.to_bytes().slice(4, 32), 1)
    let reward_addr = serLib.RewardAddress.from_address(serLib.Address.from_bytes(reward_addr_bytes))
    let cur_stake_address = reward_addr.to_address().to_bech32()


    //console.log(tokens_list)
    

    setTempWalletInfo({
      cur_wallet: cur_wallet,
      address: address_decoded,
      stake_address: cur_stake_address,
      lovelace_balance: lovelace_balance,
      tokens_list: tokens_list
    })



    /*
    setAddress(address_decoded)
    setStakeAddress(cur_stake_address)

    setLovelace(lovelace_balance)

    setTokens(tokens_list)
    */

    //setIsBusy(false)




  }



useEffect(() => {

  if (tempWalletInfo){
    let cur_wallet = tempWalletInfo.cur_wallet

    if (activeWallet == cur_wallet){
      /*
        console.log(tempWalletInfo)
        console.log(tempWalletInfo.address)
        console.log(tempWalletInfo.stake_address)
        console.log(tempWalletInfo.lovelace_balance)
        console.log(tempWalletInfo.tokens_list)
      */
       setAddress(tempWalletInfo.address)
       setStakeAddress(tempWalletInfo.stake_address)
       setLovelace(tempWalletInfo.lovelace_balance)
       setTokens(tempWalletInfo.tokens_list)
       setIsBusy(false)
    }
}

}, [tempWalletInfo])


  async function updateWallet(wallet){
        let date = new Date();
        let expDays = 120
        let expTime = expDays * 24 * 60 * 60 * 1000
        date.setTime(date.getTime() + expTime);
        const expires = "expires=" + date.toUTCString();
        document.cookie = String('wallet') + " = " + String(wallet) + '; ' + expires + "; path=/"
  }


   function checkWallet(){
        let cookie = document.cookie
        let cookies = cookie.split(';')

        let cookies_dict = {}

        for (let i = 0; i < cookies.length; i = i + 1){
            let temp = cookies[i]
            while (temp.charAt(0) == ' ') {
                temp = temp.substring(1);
            }
            let entry = temp.split('=')

            cookies_dict[entry[0]] = entry[1]
        }
       return cookies_dict['wallet']
  }


useEffect(() => {
    async function onAccountChange() {
      try{
      let namiAPI = await window.cardano.nami
      let isNamiEnabled = await namiAPI.isEnabled()
      //console.log(isNamiEnabled)

      if(isNamiEnabled){
        namiAPI = await namiAPI.enable()
         namiAPI.experimental.on('accountChange',()=>{
          setAccountChange('nami')
          //console.log('Nami account changed')
         })
      }

    }catch{
      //notifyError('oh no...')
    }

  }
    setTimeout(onAccountChange, 100)
    setTimeout(function checkWalletInCookies(){
    //setIsBusy(true)
    let currentWallet = checkWallet()

    //console.log('CURRENT WALLET')
    //console.log(currentWallet)

    if (typeof(currentWallet) == 'string'){
        setIsBusy(true)
        setActiveWallet(currentWallet)
        enableWallet(currentWallet)
    }

  }, 100)
}, [])



useEffect(() => {
  if(activeWallet == accountChange){
    //console.log('Switching...')
    setAccountChange()
    getWalletBalance()
  }else{
    if(accountChange){
      //console.log('Business as usual')
      setAccountChange()
    }
  }

}, [accountChange])


useEffect(() => {
  setIsWalletLoading(isBusy)
}, [isBusy])


useEffect(() => {

  if (activeWallet){
     getWalletBalance()
  }

}, [activeWallet])


  async function enableWallet(wallet){

      if (wallet == activeWallet){
          setIsBusy(false)
          return
      }

      /*
      console.log('NEW WALLET')
      console.log(wallet)

      console.log('OLD WALLET')
      console.log(activeWallet)
      */

      let cur_wallet = activeWallet
      setActiveWallet()
      try{
        let cardano = window.cardano[wallet]
      try{
        let walletAPI = await cardano.enable()
        setActiveWallet(wallet)
        updateWallet(wallet)
      }catch{
        setIsBusy(false)
        notifyError('Declined to connect to ' + wallet.charAt(0).toUpperCase() + wallet.slice(1) + ' wallet')
        setActiveWallet(cur_wallet)
      }
    }catch{
      setIsBusy(false)
      notifyError(wallet.charAt(0).toUpperCase() + wallet.slice(1) + ' wallet is not installed')

      setActiveWallet(cur_wallet)
    }
  } 


  return (
    <div onMouseEnter={()=>{setButtonActive(true)}} onMouseLeave={()=>{setButtonActive(false)}} style={{marginTop:'0px', 'position':'relative', 'height':'300px'}}>
      
      {activeWallet && lovelace && !isBusy ? (
      <DisplayAccount walletLogos={walletLogos} activeWallet={activeWallet} lovelace={lovelace} matchesS={matchesS} />)
      : (

      <ConnectButton theme={!buttonActive && !isBusy ? theme : {anim:'none',op: 1}} matchesS={matchesS}>
      {isBusy ? (<CircularProgress thickness={4} style={matchesS ? {zIndex:'4', color: "#53c8ee"} : {zIndex:'4', color: "#53c8ee", width:'25px',height:'25px'}} />) : (
      <ButtonText style={{fontSize:matchesS ? '1.8em' :'15px'}}>Connect</ButtonText>
      )}
      </ConnectButton>
     )}

      {buttonActive /*&& !isBusy*/ ? 
      <div style={{position:'relative',top: pushDown ? '0px' : '0px'}}>

      <DropDownItem onClick={() => {
        setButtonActive(false)
        setIsBusy(true)
        enableWallet('nami')}}>
         {isBusy && activeWallet == 'nami' ? <div style={{marginLeft:'-10px', marginRight:'10px',zIndex:'5'}}> <CircularProgress style={{width:'30px',height:'30px', zIndex:'5', color:'#53c8ee'}} /> </div>: 
        <img src='/nami.svg' style={{width:'30px',height:'30px', zIndex:'4', marginLeft:'-20px', marginRight:'20px'}} / >}
        <ButtonText style={{fontSize:'1.1em'}} >NAMI</ButtonText>
      </DropDownItem>

      <DropDownItem onClick={()=>{
        setButtonActive(false)
        setIsBusy(true)
        enableWallet('eternl')}}>
        {isBusy && activeWallet == 'eternl' ? <div style={{marginLeft:'5px', marginRight:'20px',zIndex:'5'}}> <CircularProgress style={{width:'30px',height:'30px', zIndex:'5', color:'#53c8ee'}} /> </div>: 
         <img src='/eternl.png' style={{width:'30px',height:'30px', zIndex:'4', marginLeft:'10px', marginRight:'20px'}} / >}
        <ButtonText style={{fontSize:'1.1em'}} >Eternl</ButtonText>
      </DropDownItem>

      <DropDownItem onClick={()=>{
        setButtonActive(false)
        setIsBusy(true)
        enableWallet('yoroi')}}>
        {isBusy && activeWallet == 'yoroi' ? <div style={{marginLeft:'-10px', marginRight:'10px',zIndex:'5'}}> <CircularProgress style={{width:'30px',height:'30px', zIndex:'5', color:'#53c8ee'}} /> </div>: 
         <img src='/yoroi.svg' style={{width:'30px',height:'30px', zIndex:'4', marginLeft:'-5px', marginRight:'20px'}} / >}
        }
        <ButtonText style={{fontSize:'1.1em'}} >Yoroi</ButtonText>
      </DropDownItem>

      
      <DropDownItem onClick={()=>{
        setButtonActive(false)
        setIsBusy(true)
        enableWallet('flint')}}>
        {isBusy && activeWallet == 'flint' ? <div style={{marginLeft:'-10px', marginRight:'10px',zIndex:'5'}}> <CircularProgress style={{width:'30px',height:'30px', zIndex:'5', color:'#53c8ee'}} /> </div>: 
         <img src='/flint.svg' style={{width:'30px',height:'30px', zIndex:'4', marginLeft:'-5px', marginRight:'20px'}} / >}
        <ButtonText style={{fontSize:'1.1em'}} >Flint</ButtonText>
      </DropDownItem>

      </div>

      : 
      null
    }

    </div>

  );
}

export default Connect;
