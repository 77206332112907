import React from 'react';
import { useEffect, useRef } from "react";
import { useState } from 'react';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import {FBXLoader} from 'three/examples/jsm/loaders/FBXLoader.js';

import Box from '@mui/material/Box';


function ModelView(props)
{
	const handle = useFullScreenHandle();
	const mountRef = useRef(null);
	const [expandedView, setExpandedView] = useState();
	
    const modelUrl = props.model_url
    const collection = props.collection
    let background_col = props.background_col

    useEffect(() => {
    var containerElement = document.getElementById(props.model_name);

    let paddingLeft = parseInt(window.getComputedStyle(containerElement, null).getPropertyValue('padding-left'))

    let width = containerElement.clientWidth + 1
    let height = width


    var scene = new THREE.Scene();
    var camera = new THREE.PerspectiveCamera( 45, width/height, 0.1, 1000 );
    var renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setSize( width, height );

    renderer.outputEncoding = THREE.sRGBEncoding;

    // lights

     const hemiLight = new THREE.HemisphereLight( 0xffffff, 0x444444 );
     hemiLight.position.set( 0, 20, 0 );
     scene.add( hemiLight );

     const ambientLight = new THREE.AmbientLight()
     ambientLight.intensity = 0.1
     scene.add(ambientLight)

     const dirLight = new THREE.DirectionalLight( 0xffffff );
     dirLight.position.set( 0, 15, 10 );
     dirLight.intensity = 0.6;
     scene.add( dirLight );


    window.addEventListener( 'resize', onWindowResize, false );



    const controls = new OrbitControls(camera, renderer.domElement)
    controls.enableDamping = true

    controls.minPolarAngle = 0;
    controls.maxPolarAngle = Math.PI;
    controls.minDistance = 3;
    controls.maxDistance = 10;

    controls.enablePan = true;

    let rotationSpeed = Math.max(Math.min(Math.min(width / window.innerWidth, height / window.innerHeight), 1),0.5)

    controls.rotateSpeed = rotationSpeed



    function onWindowResize(){


        if(!document.fullscreenElement){
            width = containerElement.clientWidth - paddingLeft
            height = width

            rotationSpeed = Math.max(Math.min(Math.min(width / window.innerWidth, height / window.innerHeight), 1),0.5)
            controls.rotateSpeed = rotationSpeed
        }else{
            width = window.innerWidth
            height = window.innerHeight

            rotationSpeed = 1
            controls.rotateSpeed = rotationSpeed
        }
        camera.aspect = width / height;
        camera.updateProjectionMatrix();
        renderer.setSize( width, height );
    }

    mountRef.current.appendChild( renderer.domElement );

    var geometry = new THREE.BoxGeometry( 1, 1, 1 );
    var material = new THREE.MeshBasicMaterial( { color: 0x00ff00 } );
    var cube = new THREE.Mesh( geometry, material );


    camera.position.z = 9;
    camera.position.y = 4.5;

    controls.target = new THREE.Vector3(0, 2.5, 0);


    
    background_col = background_col.split(' ').join('_');


    const loader = new THREE.CubeTextureLoader();
    const texture = loader.load([
        'https://claynation.nyc3.digitaloceanspaces.com/'+collection+'backgrounds/'+background_col+'.png',
        'https://claynation.nyc3.digitaloceanspaces.com/'+collection+'backgrounds/'+background_col+'.png',
        'https://claynation.nyc3.digitaloceanspaces.com/'+collection+'backgrounds/'+background_col+'.png',
        'https://claynation.nyc3.digitaloceanspaces.com/'+collection+'backgrounds/'+background_col+'.png',
        'https://claynation.nyc3.digitaloceanspaces.com/'+collection+'backgrounds/'+background_col+'.png',
        'https://claynation.nyc3.digitaloceanspaces.com/'+collection+'backgrounds/'+background_col+'.png',
    ]);
   
    texture.encoding = THREE.sRGBEncoding;    

    scene.background = texture;


    //LOAD HERE
    async function loadModel() {
        let modelLoader = new FBXLoader();
	let temp_scale = 0.025;	

        modelLoader.load( modelUrl, ( object ) => {


        object.scale.setScalar(temp_scale)
	
	        object.traverse( function ( child ) {

        if ( child instanceof THREE.Mesh ) {


            for (let i = 0; i < child.material.length; i++){
                let cur_material = child.material[i]

                cur_material.normalScale = new THREE.Vector2(-1, -1);

                const color2 = new THREE.Color( 0x111111);

                child.material[i].specular = color2;

		const color3 = new THREE.Color( 0xff0000 );

                if (child.material[i].emissiveMap){
                    child.material[i].emissive = color3;
                }

		if (child.material[i].alphaMap){
                    child.material[i].alphaMap = null;
                }
            }
        }

        } );

        scene.add( object );

        } );
    }

    loadModel();



    var animate = function () {
      requestAnimationFrame( animate );
      controls.update()
      renderer.render( scene, camera );
    };
    
    animate();
   
    	return () =>  {try{mountRef.current.removeChild( renderer.domElement);
    }catch(err){
    }
}
  }, []);

const expandView = () => {
	
	if(!document.fullscreenElement){
		handle.enter()
	}	
}

return(
	<FullScreen handle={handle}>

		<Box id='model3D' ref={mountRef}  onDoubleClick={expandView} style={{ minWidth: '100vw', width: '110%'}}></Box>

	</FullScreen>
	)
}
export default ModelView;