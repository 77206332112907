import './EmptyWallet.css'

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

function EmptyWallet(props) {

  const matchesS = props.matchesS

  return (

 

    <div className="placeholderNoassets" style={{width:'50%', marginTop:'60px', marginBottom:'60px'}}>
     

    <Grid container  justifyContent="center" alignItems="center" style={{height:'100%'}}>
    
  <Stack spacing={8}>
<Grid item xs={12}>
     <div className="noAssets">
       NO ASSETS FOUND
     </div>
</Grid>


<Grid item xs={12}>

       <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={3}

        >
      <div className="getThem">
          GET THEM ON
      </div>

      <button className="jpgstoreBtn" onClick={() => window.open('https://clayspace.io/','_blank')}>

         <Stack
          direction="row"
          justifyContent="space-evenly"
          alignItems="center"
          spacing={0.5}
        >

          <img src='Tent_Icon.png' style={{width:'40px', marginTop:'-5px'}} />

          <div className="btnTxt" style={{marginRight:'0px', color:'bisque', textShadow: '#ffc9bc 1px 0 10px'}}>

            clayspace.io

          </div>
      

          </Stack>


      </button>

 
      </Stack>

</Grid>



      
  </Stack>
  </Grid>
</div>

  

  );
}

export default EmptyWallet;