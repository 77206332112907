import './App.css';
import WalletContext from './components/WalletContext'

function Wallet() {
  return (
    <div className="App">

      <div style={{background:'url(/Background.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center center', height: '100vh'}}>
       
        <WalletContext />

      </div>

    </div>
  );
}

export default Wallet;