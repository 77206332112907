import {useReducer, useState, createContext, useEffect} from 'react'

import Stack from '@mui/material/Stack';

import './Filters.css'
import './StakingSideBar.css'


function CollectionsToggle(props) {

    const includeCn = props.includeCn
    const setIncludeCn = props.setIncludeCn

    const includeGc = props.includeGc
    const setIncludeGc = props.setIncludeGc

    const totalCn = props.totalCn
    const totalGc = props.totalGc

  return (

  		 <Stack
                  direction="column"
                  justifyContent="space-evenly"
                  alignItems="flex-start"
                  spacing={2}

                  style={{width:'80%', marginLeft:'60px', marginTop:'20px'}}
                >


                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}

                  style={{width:'90%', textAlign:'start'}}

                >

                
                <div style={{color:'bisque',fontSize:'16px', fontFamily:'shortstack'}}>Clay Nation</div>
               

                <div>
                <div className={includeCn ? "cn-toggle-on" : "cn-toggle-off"} onClick={()=>{
                     
                    //setSearchBarMode(false)

                    let cur_state = includeCn 

                    if (cur_state == true && includeGc == false){
                        setIncludeGc(true)
                    }

                    setIncludeCn(!includeCn)
                }}>
                     <div className={includeCn ? "notch-CN-on" : "notch-CN-off"}></div>
                </div>
                </div>
                

                </Stack>


                <div style={{position:'relative', width:'110px', padding:'0px', margin:'5px',height:'20px', display:'flex', textAlign:'center', justifyContent:'center', alignItems:'center'}}> 
                  <div className="stakingAddress" style={{width:'40px'}}>     
                   <h1 style={{fontSize:'18px'}}>{totalCn}</h1>
                  </div>
                </div>


              

                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}

                  style={{width:'90%',textAlign:'start'}}

                >

               
                <div style={{color:'bisque',fontSize:'16px',fontFamily:'shortstack'}}>Clay Nation x<br/>Good Charlotte</div>


                
                <div>

                <div className={includeGc ? "gc-toggle-on" : "cn-toggle-off"} onClick={()=>{
                    
                    //setSearchBarMode(false)
                    let cur_state = includeGc

                    if (cur_state == true && includeCn == false){
                        setIncludeCn(true)
                    }

                    setIncludeGc(!includeGc)
                }}>
                     <div className={includeGc ? "notch-GC-on" : "notch-CN-off"}></div>
                </div>


                </div>


                </Stack>



                <div style={{position:'relative', width:'110px', padding:'0px', margin:'5px',height:'20px', display:'flex', textAlign:'center', justifyContent:'center', alignItems:'center'}}> 
                  <div className="stakingAddress" style={{width:'40px'}}>     
                   <h1 style={{fontSize:'18px'}}>{totalGc}</h1>
                  </div>
                </div>




                </Stack>
   
  );
}

export default CollectionsToggle;









      