import './App.css';
import MyRoutes from './Routes'

function App() {
  return (
    <MyRoutes />
  );
}

export default App;
