
import { useState, useContext, useEffect } from 'react';
import {MainClaimContext} from './WalletContext'

import {Buffer} from 'buffer'

import './StakingSideBar.css'

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import Card from './Card'
import EmptyWallet from './EmptyWallet'

import cn_meta from '../cn_info.json'
import gc_meta from '../gc_info.json'


function Wallet(props) {

   const setHasAssets = props.setHasAssets
   const authToken = props.authToken

   const setAuthToken = props.setAuthToken

    const includeCn = props.includeCn

    const includeGc = props.includeGc

    const setTotalCn = props.setTotalCn
    const setTotalGc = props.setTotalGc

    const isWalletOpen = props.isWalletOpen


   const {activeWallet, address, stake_address, lovelace, tokens, selectedTokens, setActiveWallet, setAddress, setStakeAddress, setLovelace, setTokens, setSelectedTokens, reset} = useContext(MainClaimContext)
   const eligible_policies = ['40fa2aa67258b4ce7b5782f74831d46a84c59a0ff0c28262fab21728','94da605878403d07c144fe96cd50fe20c16186dd8d171c78ed6a8768']

   const clay_info = {'40fa2aa67258b4ce7b5782f74831d46a84c59a0ff0c28262fab21728':{collection:'cn',base_name:'ClayNation'}, '94da605878403d07c144fe96cd50fe20c16186dd8d171c78ed6a8768':{collection:'gc',base_name:'ClayCharlotte'}}
  
   const [testTokens, setTestTokens] = useState([])

   const [assets, setAssets] = useState([])


   const [model3D, setModel3D] = useState('')


   

   useEffect(() => {

      let temp = []

      let cn = []
      let gc = []

      for (let i = 0; i < tokens.length; i++){
        
        let cur_unit = tokens[i]['unit']
        let cur_policy = cur_unit.slice(0,56)

        
        if (cur_policy in clay_info ){
          let cur_collection = clay_info[cur_policy]['collection']
          let cur_base_name = clay_info[cur_policy]['base_name']

          let asset_name_hex = cur_unit.slice(56,)

          let asset_name = Buffer.from(asset_name_hex,'hex').toString('utf-8')

          let serial = parseInt(asset_name.slice(cur_base_name.length,))

          if (cur_collection == 'cn'){
            
            let cur_image = cn_meta[serial]['image']
            let cur_background = cn_meta[serial]['background']
            cn.push({collection:'cn', serial:serial, image:cur_image, background: cur_background})
            
          }else if (cur_collection == 'gc'){
             
             let cur_image = gc_meta[serial]['image']
             let cur_background = gc_meta[serial]['background']
             //console.log(cur_background)
             gc.push({collection:'gc', serial:serial, image:cur_image, background: cur_background})
          }

        }

      }

      if (includeCn){
        temp = temp.concat(cn)
      }

      if (includeGc){
        temp = temp.concat(gc)
      }

      setTotalCn(cn.length)

      setTotalGc(gc.length)


      if (temp.length > 0){
        setHasAssets(true)
      }else{
        setHasAssets(false)
      }

      setAssets(temp)



   },[tokens, includeCn, includeGc])

  return (

     <Grid container justifyContent="center" alignItems="center" style={{marginTop:'30px'}} style={{width:'100%'}}>


      

          {
            assets && assets.length > 0 ? 

            assets.map((token)=>{
              return(
               
                <Grid item xs={12} sm={12} md={5} lg={4} xl={3} key={token.collection.toLowerCase()+'#'+String(token.serial)} >
                <div style={{position:'relative', display:'flex', justifyContent:'center'}}>
                 <Card serial={token.serial} collection={token.collection} image={token.image} background={token.background} model3D={model3D} setModel3D={setModel3D} authToken={authToken} setAuthToken={setAuthToken} />
                </div>
                </Grid>
                 )
            })
           
            :

            isWalletOpen ?
            
            <EmptyWallet />

            : 

            null
          }



       


      </Grid>
  );
}

export default Wallet;