import { useState, useEffect, useContext} from 'react'
import {MainClaimContext} from './WalletContext'

import './StakingSideBar.css'

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';


function AccountSummary(props) {

  const {activeWallet, address, stake_address, lovelace, tokens, selectedTokens, setActiveWallet, setAddress, setStakeAddress, setLovelace, setTokens, setSelectedTokens, reset} = useContext(MainClaimContext)


  const setIsWalletOpen = props.setIsWalletOpen
  const setIsDiscord = props.setIsDiscord

  const eligible_policies = ['40fa2aa67258b4ce7b5782f74831d46a84c59a0ff0c28262fab21728','94da605878403d07c144fe96cd50fe20c16186dd8d171c78ed6a8768']
  const clay_policy = "38ad9dc3aec6a2f38e220142b9aa6ade63ebe71f65e7cc2b7d8a8535"

  const [adaBalance, setAdaBalance] = useState()
  const [clayBalance, setClayBalance] = useState()


  function getAdaBalance() {


     if (lovelace != null){
      let ada_balance = lovelace / 1000000

    if (ada_balance < 1000){
      ada_balance = (parseInt(lovelace) / 1000000).toFixed(2);
    }else if(ada_balance < 1000000){
      ada_balance = (parseInt(lovelace) / 1000000000).toFixed(2) + 'K';
    }else{
      ada_balance = (parseInt(lovelace) / 1000000000000).toFixed(2) + 'M';
    }

    setAdaBalance(ada_balance)

    }

  }

  const getFormattedBalance = (coin_balance, decimals, fixed_dec) => {
      let denominator = Math.pow(10,decimals)
      let whole_coins = coin_balance / denominator
      let formatted_amount = ''
      if (whole_coins < 1000){
        formatted_amount = (parseInt(coin_balance) / denominator).toFixed(fixed_dec);
      }else if(whole_coins < 1000000){
        formatted_amount = (parseInt(coin_balance) / (denominator*1000)).toFixed(fixed_dec) + 'K';
      }else{
        formatted_amount = (parseInt(coin_balance) / (denominator * 1000000)).toFixed(fixed_dec) + 'M';
      }

      return formatted_amount
}


  function getClayBalance(){

    let clay_cents = 0

    for (let i = 0; i < tokens.length; i++){
      //console.log(tokens[i])

      if (tokens[i]['unit'].slice(0,56) == clay_policy){
        clay_cents = clay_cents + parseInt(tokens[i]['quantity'])
      }

    }

    let clay_full = getFormattedBalance(clay_cents,4,2)

    setClayBalance(clay_full)

  }




  useEffect(() => {

    getAdaBalance()
    getClayBalance()

    if (stake_address && address){
      setIsWalletOpen(true)
    }
    else{
      setIsWalletOpen(false)
    }

    if (address == 'Verified Holder'){
      console.log('VERIFIED HOLDER')
      setIsDiscord(true)
      setIsWalletOpen(true)
    }



  },[stake_address, address])

/*
  let ada_balance = cur_lovelace / 1000000
  
  if (ada_balance < 1000){
    ada_balance = (parseInt(cur_lovelace) / 1000000).toFixed(2);
  }else if(ada_balance < 1000000){
    ada_balance = (parseInt(cur_lovelace) / 1000000000).toFixed(2) + 'K';
  }else{
    ada_balance = (parseInt(cur_lovelace) / 1000000000000).toFixed(2) + 'M';
  }
*/

  return (
     <div style={{position:'relative',display:'flex',justifyContent:'center'}}>

     {address && address != 'Verified Holder' ?

      <div>
        <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >


            <Grid item xs={12} style={{ position:'relative', width:'100%', display:'flex',justifyContent:'center', textAlign:'start'}}>    
                <div className="tokensText" style={{width:'80%',color:'#a8afa6', fontSize:'16px', fontFamily:'shortstack'}}>Address</div>
            </Grid>

            <Grid item xs={12} style={{ position:'relative', width:'100%', display:'flex',justifyContent:'center'}}>                
                  <div className="stakingAddress" style={{height:'65px', width:'100%'}}><Stack spacing={0} style={{overflowWrap:'anywhere'}}> <span className="tokensNumber" style={{color:'#e7e6d2'}}></span><span className="tokensText" style={{color:'#e7e6d2', fontFamily:'shortstack'}}>{address}</span> </Stack> </div>
            </Grid>

            </Stack>



        <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >


            <Grid item xs={12} style={{ position:'relative', width:'100%', display:'flex',justifyContent:'center', textAlign:'start', marginTop:'15px'}}>    
                <div className="tokensText" style={{width:'80%',color:'#a8afa6', fontSize:'16px', fontFamily:'shortstack'}}>Stake Address</div>
            </Grid>

            <Grid item xs={12} style={{ position:'relative', width:'100%', display:'flex',justifyContent:'center'}}>                
                  <div className="stakingAddress" style={{height:'50px', width:'100%'}}><Stack spacing={0} style={{overflowWrap:'anywhere'}}> <span className="tokensNumber" style={{color:'#e7e6d2'}}></span><span className="tokensText" style={{color:'#e7e6d2', fontFamily:'shortstack'}}>{stake_address}</span> </Stack> </div>
            </Grid>

            </Stack>

      

         <Grid item xs={4}>

            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={0}
            >

            <Grid item xs={12} style={{ position:'relative', width:'100%', display:'flex',justifyContent:'center', textAlign:'center',marginBottom:'5px', marginTop:'15px'}}>    
                <div className="tokensText" style={{width:'100%',color:'#a8afa6', fontSize:'16px', fontFamily:'shortstack'}}>Balance</div>
            </Grid>



            <Grid item xs={12} style={{ position:'relative', width:'100%', display:'flex',justifyContent:'center', marginBottom:'10px'}}>                
                  <div className="stakingAddress" style={{minHeight:'40px', minWidth:'90px'}}>


                  <Stack spacing={0.5} style={{overflowWrap:'anywhere'}}> <span className="tokensNumber" style={{color:'white'}}></span>
                  

                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"

                    spacing={0.5}

                    style={{width:'100%'}}
                  >

                  <div>
                  </div>
                  <span className="tokensText" style={{color:'#fdfdfc', fontFamily:'shortstack'}}>

                  {adaBalance}

                   </span>

                  <img src="/Cardano.svg" style={{height:'25px', width:'25px', filter: 'contrast(200%)'}} />
                  </Stack>


                 <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"

                    spacing={0.5}

                    style={{width:'100%'}}
                  > 

                  <div></div>
                  
                  <span className="tokensText" style={{color:'#fdfdfc', fontFamily:'shortstack'}}>

                  {clayBalance}

                  </span>

                  <img src="/CLAY_TOKEN_220x220.png" style={{height:'25px', width:'25px'}} />


                  </Stack>








                   </Stack> </div>
            </Grid>

                        </Stack>

            </Grid>




            </div>

            :

            address == 'Verified Holder' ?

            <div>
              <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >



            <Grid item xs={12} style={{ position:'relative', width:'30vw', minWidth:'250px', display:'flex',justifyContent:'center'}}>                
                  <div className="stakingAddress" style={{height:'50px', width:'100%'}}><Stack spacing={0} style={{overflowWrap:'anywhere'}}> <span className="tokensNumber" style={{color:'#e7e6d2'}}></span><span className="tokensText" style={{color:'#e7e6d2', fontFamily:'shortstack'}}>Verified Holder</span> </Stack> </div>
            </Grid>

            </Stack>
            </div>

            :

            null

           }

     </div>
  );
}

export default AccountSummary;
