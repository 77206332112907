import { useRef, useState, useEffect, useContext} from 'react'

import {Buffer} from 'buffer'

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

import Menu from '@mui/material/Menu';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';

import ListItemText from '@mui/material/ListItemText';

function DownloadOptions(props) {

  const textColor = props.textColor
  const model_name = props.model_name

  const isDownload = props.isDownload
  const setIsDownload = props.setIsDownload

  const downloadRef = props.downloadRef

  const collection = props.collection

  const accessModel3D = props.accessModel3D

  const [clickedOutside, setClickedOutside] = useState(false);


  const clayInfo = {'cn':{policy_id:'40fa2aa67258b4ce7b5782f74831d46a84c59a0ff0c28262fab21728',base_name:'ClayNation'}, 'gc':{policy_id:'94da605878403d07c144fe96cd50fe20c16186dd8d171c78ed6a8768',base_name:'ClayCharlotte'}}
  



  async function downloadModel(model_name, format){

    let cur_collection = model_name.split('#')[0]
    let cur_serial = model_name.split('#')[1]
    let asset_id = clayInfo[cur_collection]['policy_id'] + Buffer.from(clayInfo[cur_collection]['base_name']+String(cur_serial)).toString('hex')

    const file_url = await accessModel3D(asset_id,format)

    window.open(file_url, '_blank');



  }

  const menuRef = useRef();

      useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    });


  const handleClickOutside = e => {
        if (!menuRef.current.contains(e.target)) {
            //console.log('Outside')
            setClickedOutside(true);

            if (!downloadRef.current.contains(e.target)){
              setIsDownload(false)
            }
        }
    };

    const handleClickInside = () => {
      //console.log('inside')
      setClickedOutside(false)
    }



  return (

   <div ref={menuRef} onClick={()=>{

    handleClickInside()

   }} >

    <Paper

      sx={{
        position:'absolute',
        display:'flex',
        ml:'-80px',
        width: 128,
        height: 136,
        backgroundColor:'#253a48',
        borderRadius:'20px',
        alignItems:'center',
        justifyContent:'center',
        color:textColor,
        opacity:'0.9'
     
    }}
    >


    <MenuList >

    <MenuItem sx={{

      minHeight:'35px',

      "&:hover": {
      backgroundColor: "#415464",
      borderRadius:'5px'
 
    }}}

    onClick={()=>{


      downloadModel(model_name,'fbx')
    }}

    >
        <ListItemText>FBX</ListItemText>
    </MenuItem>

    <MenuItem

    disabled={collection=='gc' ? true : false}

     sx={{

      minHeight:'35px',

      "&:hover": {
      backgroundColor: "#415464",
      borderRadius:'5px'
 
    }}}

    onClick={()=>{


      downloadModel(model_name,'unreal')
    }}

    >
        <ListItemText>FBX (Unreal)</ListItemText>
    </MenuItem>

    <MenuItem
    disabled={collection=='gc' ? true : false}

     sx={{

      minHeight:'35px',

      "&:hover": {
      backgroundColor: "#415464",
      borderRadius:'5px'
 
    }}}

    onClick={()=>{


      downloadModel(model_name,'unity')
    }}

    >
        <ListItemText>FBX (Unity)</ListItemText>
    </MenuItem>

    </MenuList>




    </Paper>

   </div>
      
  );
}

export default DownloadOptions;
